<template>
  <b-row align-h="center">
    <b-col md="8">
      <b-card>
        <b-row>
          <b-col cols="12 mb-1">
            <b-badge variant="light-secondary" v-if="form.status === 0">{{
              form.status_name
            }}</b-badge>
            <b-badge variant="light-success" v-else-if="form.status === 1">{{
              form.status_name
            }}</b-badge>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <b-row>
              <b-col cols="6" md="6" class="text-left"> Order Code </b-col>
              <b-col cols="6" md="6" class="text-right font-weight-bolder">
                {{ form.code }}
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="6" md="6" class="text-left"> Order Date </b-col>
              <b-col cols="6" md="6" class="text-right font-weight-bolder">
                {{ moment(form.created_at) }} WIB
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="6" md="6" class="text-left"> Customer </b-col>
              <b-col cols="6" md="6" class="text-right font-weight-bolder">
                {{ form.customer_name }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <label class="font-weight-bolder text-primary mb-1" for="product"
          >List Product</label
        >
        <b-row class="mb-1" v-for="(product, list) in products" :key="list">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" md="12">
                <b-img
                  thumbnail
                  fluid
                  :src="product.image"
                  width="150"
                  class="float-left mr-1"
                  style="height: 150px; object-fit: contain"
                />
                <b-row>
                  <b-col md="6">
                    <div class="mb-2 font-weight-bolder">
                      {{ product.code }}
                    </div>
                    <div class="text-success font-weight-bolder">
                      {{ product.name }}
                    </div>
                    <div>
                      {{ product.qty }} {{ product.unit }} X
                      {{ formatRupiah(product.price) }}
                    </div>
                  </b-col>
                  <b-col md="6" class="text-right mt-3">
                    <div>Subtotal Price</div>
                    <div class="font-weight-bolder">
                      {{ formatRupiah(product.total_price) }}
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- <b-row>
                    <b-col md="6" class="font-weight-bolder text-right">Total</b-col>
                    <b-col md="6" class="font-weight-bolder text-primary text-right"><span class="mr-1">{{ formatRupiah(form.purchase_price) }}</span></b-col>
                </b-row> -->
      </b-card>
      <b-card>
        <label class="font-weight-bolder text-primary mb-1" for="product"
          >Payment Details</label
        >
        <b-row>
          <b-col cols="6" md="3"> Payment Type </b-col>
          <b-col cols="6" md="9" class="text-right font-weight-bolder">
            {{ form.payment_type_name }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" md="3"> Purchase Price </b-col>
          <b-col cols="6" md="9" class="text-right font-weight-bolder">
            {{ formatRupiah(form.purchase_price) }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" md="3"> Tax </b-col>
          <b-col cols="6" md="9" class="text-right font-weight-bolder">
            {{ formatRupiah(form.tax) }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="6" md="3"> Discount </b-col>
          <b-col
            cols="6"
            md="9"
            class="text-right font-weight-bolder text-warning"
          >
            {{ "- " + formatRupiah(form.discount) }}
          </b-col>
        </b-row>
        <hr class="height-5" />
        <b-row>
          <b-col cols="6" md="3"> Total </b-col>
          <b-col
            cols="6"
            md="9"
            class="text-right font-weight-bolder text-success"
          >
            {{ formatRupiah(form.gross_price) }}
          </b-col>
        </b-row>
      </b-card>
      <b-row v-if="form.status === 0">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="danger"
            class="mr-1"
            block
            @click="deleteOrder()"
            v-if="permissions.includes('sales-order-delete')"
          >
            Delete
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BContainer,
  BBadge,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
    BImg,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        created_at: "",
        purchase_price: null,
        tax: null,
        discount: null,
        gross_price: null,
        status: null,
        status_name: "",
        reject_reason: "",
        customer_name: "",
        payment_type: null,
        payment_type_name: "",
      },
      permissions: [],
      products: [],
      logs: [],
      url: "",
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
    this.getDetailsItem();
  },
  methods: {
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http.get("sales/order/" + id).then((response) => {
        this.form.code = response.data.data.code;
        this.form.created_at = response.data.data.created_at;
        this.form.purchase_price = response.data.data.purchase_price;
        this.form.tax = response.data.data.tax;
        this.form.discount = response.data.data.discount;
        this.form.gross_price = response.data.data.gross_price;
        this.form.status = response.data.data.status;
        this.form.status_name = response.data.data.status_name;
        this.form.customer_name = response.data.data.customer_name;
        this.form.payment_type = response.data.data.payment_type;
        this.form.payment_type_name = response.data.data.payment_type_name;
      });
    },
    getDetailsItem() {
      const id = this.$route.params.id;
      this.$http.get("sales/order/" + id + "/items").then((response) => {
        // console.log(response.data.data)
        const { baseUrl } = $themeConfig.app;
        this.url = baseUrl;
        this.url += "storage/product/";

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            category: datas[i].category,
            code: datas[i].code,
            id: datas[i].id,
            image: this.url + datas[i].image,
            name: datas[i].name,
            price: datas[i].price,
            product_id: datas[i].product_id,
            qty: datas[i].qty,
            total_price: datas[i].total_price,
            unit: datas[i].unit,
          };
          this.products.push($data);
        }
      });
    },

    deleteOrder() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/order/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Order has been deleted",
              variant: "danger",
            },
          });
          location.href = "/sales/order";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>